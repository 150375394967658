import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import Fetch from "../../api/Fetch";
import { BACKEND_URI } from "../../constants";
import EventStore from "../../stores/EventStore";
import { Location } from "../../model/Types";
import Toaster from "../../common/Toaster";

const AdminEventLocations = observer(() => {
    const [locations, setLocations] = React.useState<Location[]>([]);
    const [currentLocation, setCurrentLocation] = React.useState<Location | undefined>(undefined);
    const [open, setOpen] = React.useState<boolean>(false);

    const cb = React.useCallback(async () => {
        try {
            const data = await Fetch<Location[]>(`${BACKEND_URI}/admin/event/${EventStore.eventId}/location`, {
                includeAuth: true,
            });
            setLocations(data);
        } catch (err) {
            console.log(err);
        }
    }, []);

    const onSave = async (e: any) => {
        try {
            const body = await Fetch(`${BACKEND_URI}/admin/event/${EventStore.eventId}/location`, {
                method: "POST",
                body: currentLocation,
                includeAuth: true,
            });
            Toaster.success("Location saved successfully.");
            cb();
            setOpen(false);
        } catch (err: any) {
            console.log(err);
            Toaster.error(err.message || "An error occurred while saving location.");
        }
    };

    React.useEffect(() => {
        cb();
    }, [cb]);

    return (
        <Box sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        setCurrentLocation({
                            name: "",
                        });
                        setOpen(true);
                    }}
                >
                    Create Location
                </Button>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell width="95px"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {locations
                        ?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                        .map((it) => (
                            <TableRow component="tr" hover>
                                <TableCell>{it.name}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setOpen(true);
                                            setCurrentLocation({ ...it });
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <Dialog open={open} maxWidth="lg" fullWidth onClose={() => setOpen(false)}>
                <DialogTitle>{currentLocation?.id ? "Edit" : "Create"} Location</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ marginTop: "5px" }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Name"
                                value={currentLocation?.name}
                                onChange={(e) => {
                                    setCurrentLocation({
                                        ...currentLocation,
                                        name: e.target.value,
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: "8px" }}>
                        <Button variant="contained" color="secondary" sx={{ marginRight: "8px" }} onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={onSave}>
                            Save
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
});

export default AdminEventLocations;
