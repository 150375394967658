import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import React from "react";
import Fetch from "../api/Fetch";
import { EMS_BACKEND_URI } from "../constants";
import AuthStore from "../stores/AuthStore";
import ClearIcon from "@mui/icons-material/Close";
import ScheduleStore from "../stores/ScheduleStore";
import { Event } from "../model/Types";

const Schedule = () => {
    const [events, setEvents] = React.useState<any[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [fromDate, setFromDate] = React.useState<string>(new Date().toISOString());
    const [toDate, setToDate] = React.useState<string>(new Date().toISOString());
    const [locationId, setLocationId] = React.useState<number | undefined>(undefined);
    const [positionId, setPositionId] = React.useState<number | undefined>(undefined);

    const [loading, setLoading] = React.useState<boolean>(false);

    const fromDateOnChange = (date?: any) => {
        if (date) {
            setFromDate(date.toString());
        }
    };

    const toDateOnChange = (date?: any) => {
        if (date) {
            setToDate(date.toString());
        }
    };

    const refreshEvents = async () => {
        setLoading(true);
        try {
            const body = await Fetch<Event[]>(`${EMS_BACKEND_URI}/punch-clock/event`, {
                includeAuth: true,
            });
            setEvents(body);
        } catch (err) {
            console.log(err);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    const eventOnChange = async (e: any) => {
        if (e.target.value > 0) {
            setPage(0);
            setLoading(true);
            try {
                await ScheduleStore.eventOnChange(e.target.value);
                setFromDate(new Date(Date.parse(ScheduleStore.event?.startDate)).toISOString());
                setToDate(new Date(Date.parse(ScheduleStore.event?.endDate)).toISOString());
            } catch (err) {
                console.log(err);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            }
        }
    };

    React.useEffect(() => {
        refreshEvents();
    }, []);

    const { event, eventId } = ScheduleStore;

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Card sx={{ marginBottom: "15px" }}>
                <CardHeader title="Filters" />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="events-label-id">Event</InputLabel>
                                <Select label="Event" labelId="events-label-id" value={eventId} onChange={eventOnChange} disabled={loading}>
                                    {events.map((it) => (
                                        <MenuItem value={it.id}>{it.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel id="location-label-id">Location</InputLabel>
                                <Select
                                    label="Location"
                                    labelId="location-label-id"
                                    value={locationId}
                                    onChange={(e: any) => setLocationId(e.target.value)}
                                >
                                    <MenuItem value={undefined}>All</MenuItem>
                                    {event?.locations.map((it: any) => (
                                        <MenuItem value={it.id}>{it.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel id="position-label-id">Position</InputLabel>
                                <Select
                                    label="Position"
                                    labelId="position-label-id"
                                    value={positionId}
                                    onChange={(e: any) => setPositionId(e.target.value)}
                                >
                                    <MenuItem value={undefined}>All</MenuItem>
                                    {event?.positions.map((it: any) => (
                                        <MenuItem value={it.id}>{it.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                label="From Date"
                                onChange={fromDateOnChange}
                                renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                                value={fromDate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                label="To Date"
                                onChange={toDateOnChange}
                                renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                                value={toDate}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={{ marginBottom: "20px" }}>
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Shift / Spot</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>End Time</TableCell>
                                <TableCell>Asignee</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                                        <LinearProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (event?.shifts?.length ?? 0) === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                                        No data.
                                    </TableCell>
                                </TableRow>
                            ) : (
                                event?.shifts
                                    .filter((it: any) => !locationId || it.location.id === locationId)
                                    .filter((it: any) => !positionId || it.position.id === positionId)
                                    .map((shift: any) =>
                                        shift.recurrences
                                            .filter(
                                                (it: any) =>
                                                    DateTime.fromISO(it.date).startOf("day") >= DateTime.fromISO(fromDate).startOf("day") &&
                                                    DateTime.fromISO(it.date).endOf("day") <= DateTime.fromISO(toDate).endOf("day")
                                            )
                                            .map((recurrence: any) => {
                                                return recurrence.spots
                                                    .map((spot: any) => {
                                                        return {
                                                            shiftSpotId: spot.id,
                                                            shiftId: shift.id,
                                                            locationName: shift.location.name,
                                                            positionName: shift.position.name,
                                                            date: recurrence.date,
                                                            beginTime: DateTime.fromISO(shift.beginTime).toFormat("t"),
                                                            endTime: DateTime.fromISO(shift.endTime).toFormat("t"),
                                                            notes: shift.notes,
                                                            shiftSpot: spot,
                                                        };
                                                    })
                                                    .flat();
                                            })
                                            .flat()
                                    )
                                    .flat()
                                    .sort((a: any, b: any) =>
                                        a.shiftId > b.shiftId || (a.shiftId === b.shiftId && a.shiftSpotId > b.shiftSpotId) ? 1 : -1
                                    )
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((it: any) => (
                                        <TableRow>
                                            <TableCell>
                                                {it.shiftId} / {it.shiftSpotId}
                                            </TableCell>
                                            <TableCell>{it.locationName}</TableCell>
                                            <TableCell>{it.positionName}</TableCell>
                                            <TableCell>{it.date}</TableCell>
                                            <TableCell>{it.beginTime}</TableCell>
                                            <TableCell>{it.endTime}</TableCell>
                                            <TableCell>
                                                {it.shiftSpot.fulfilledBy?.firstName} {it.shiftSpot.fulfilledBy?.lastName}
                                            </TableCell>
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                        <TableFooter>
                            <TablePagination
                                count={
                                    event?.shifts
                                        .filter((it: any) => !locationId || it.location.id === locationId)
                                        .filter((it: any) => !positionId || it.position.id === positionId)
                                        .map((shift: any) =>
                                            shift.recurrences
                                                .filter(
                                                    (it: any) =>
                                                        DateTime.fromISO(it.date).startOf("day") >= DateTime.fromISO(fromDate).startOf("day") &&
                                                        DateTime.fromISO(it.date).endOf("day") <= DateTime.fromISO(toDate).endOf("day")
                                                )
                                                .map((recurrence: any) => {
                                                    return recurrence.spots
                                                        .map((spot: any) => {
                                                            return {
                                                                shiftSpotId: spot.id,
                                                                shiftId: shift.id,
                                                                locationName: shift.location.name,
                                                                positionName: shift.position.name,
                                                                date: recurrence.date,
                                                                beginTime: DateTime.fromISO(shift.beginTime).toFormat("t"),
                                                                endTime: DateTime.fromISO(shift.endTime).toFormat("t"),
                                                                notes: shift.notes,
                                                                shiftSpot: spot,
                                                            };
                                                        })
                                                        .flat();
                                                })
                                                .flat()
                                        )
                                        .flat().length ?? 0
                                }
                                onPageChange={(e, v) => setPage(v)}
                                page={page}
                                onRowsPerPageChange={(e) => {
                                    setRowsPerPage(parseInt(e.target.value, 10));
                                    setPage(0);
                                }}
                                rowsPerPage={rowsPerPage}
                            />
                        </TableFooter>
                    </Table>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Schedule;
