import { Button, Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { BACKEND_URI } from "../../constants";
import { OperationInfo } from "../../model/Types";
import ReactQuill from "react-quill";
import { toolbar } from "./AdminDailyInfo";
import AuthStore from "../../stores/AuthStore";
import Fetch from "../../api/Fetch";

const AdminOperationInfo = () => {
    const [eventInfo, setOperationInfo] = React.useState<OperationInfo[] | undefined>(undefined);
    const [open, setOpen] = React.useState<boolean>(false);
    const [currentInfo, setCurrentInfo] = React.useState<any>(undefined);

    const cb = React.useCallback(async () => {
        try {
            const body = await Fetch<OperationInfo[]>(`${BACKEND_URI}/admin/operation-info`, {
                includeAuth: true,
            });
            setOperationInfo(body);
        } catch (err) {
            console.log(err);
        }
    }, []);

    React.useEffect(() => {
        cb();
    }, [cb]);

    const onChangeHtml = (html: string) => {
        setCurrentInfo({
            ...currentInfo,
            html,
        });
    };

    const onSave = async (e: any) => {
        try {
            const body = await Fetch(`${BACKEND_URI}/admin/operation-info`, {
                method: "POST",
                includeAuth: true,
                body: currentInfo,
            });
            cb();
            setOpen(false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Event Name</TableCell>
                        <TableCell width="95px" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eventInfo
                        ?.sort((a, b) => a.event.name.localeCompare(b.event.name))
                        .map((it) => (
                            <TableRow>
                                <TableCell>{it.event.name}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setOpen(true);
                                            setCurrentInfo(it);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <Dialog open={open} maxWidth="lg" fullWidth onClose={() => setOpen(false)}>
                <DialogTitle>{currentInfo?.id ? "Edit" : "Create"} Operation Info</DialogTitle>
                <DialogContent>
                    <ReactQuill theme="snow" value={currentInfo?.html ?? ""} onChange={onChangeHtml} modules={toolbar} />
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: "8px" }}>
                        <Button variant="contained" color="secondary" sx={{ marginRight: "8px" }} onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={onSave}>
                            Save
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default AdminOperationInfo;
