import React from "react";
import { Event } from "../../model/Types";
import { Box, Button, Grid, InputLabel, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import ReactQuill from "react-quill";
import { toolbar } from "../../views/admin/AdminDailyInfo";

type Props = {
    event?: Event;
    onSubmit: (request: any) => void;
    onCancel?: () => void;
};

const EventForm = ({ event, onSubmit, onCancel }: Props) => {
    const [request, setRequest] = React.useState<Event>({
        id: event?.id,
        name: event?.name ?? "",
        eventStart: event?.eventStart ?? "",
        eventEnd: event?.eventEnd ?? "",
        clientId: event?.clientId ?? 0,
        timezone: event?.timezone ?? "",
        eventInfo: event?.eventInfo ?? "",
        operationInfo: event?.operationInfo ?? "",
    });

    React.useEffect(() => {
        setRequest({
            id: event?.id,
            name: event?.name ?? "",
            eventStart: event?.eventStart ?? "",
            eventEnd: event?.eventEnd ?? "",
            clientId: event?.clientId ?? 0,
            timezone: event?.timezone ?? "",
            eventInfo: event?.eventInfo ?? "",
            operationInfo: event?.operationInfo ?? "",
        });
    }, [event]);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit(request);
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="standard"
                        label="Event Name"
                        value={request.name}
                        onChange={(e) => {
                            setRequest({
                                ...request,
                                name: e.target.value,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label="Start Date"
                        value={DateTime.fromFormat(request.eventStart, "yyyy-MM-dd")}
                        onChange={(date) => {
                            setRequest({
                                ...request,
                                eventStart: date?.toFormat("yyyy-MM-dd") ?? "",
                            });
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth variant="standard" />}
                        shouldDisableDate={(date) => {
                            if (!request.eventEnd) {
                                return false;
                            }
                            return DateTime.fromFormat(request.eventEnd, "yyyy-MM-dd") < date;
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label="End Date"
                        value={DateTime.fromFormat(request.eventEnd, "yyyy-MM-dd")}
                        onChange={(date) => {
                            setRequest({
                                ...request,
                                eventEnd: date?.toFormat("yyyy-MM-dd") ?? "",
                            });
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth variant="standard" />}
                        shouldDisableDate={(date) => {
                            if (!request.eventStart) {
                                return false;
                            }
                            return DateTime.fromFormat(request.eventStart, "yyyy-MM-dd") > date;
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="standard"
                        label="Timezone"
                        value={request.timezone}
                        select
                        onChange={(e) => {
                            setRequest({
                                ...request,
                                timezone: e.target.value,
                            });
                        }}
                    >
                        <MenuItem value="America/Toronto">Toronto Time ({DateTime.now().setZone("America/Toronto").toFormat("HH:mm")})</MenuItem>
                        <MenuItem value="America/Winnipeg">Winnipeg Time ({DateTime.now().setZone("America/Winnipeg").toFormat("HH:mm")})</MenuItem>
                        <MenuItem value="America/Regina">Regina Time ({DateTime.now().setZone("America/Regina").toFormat("HH:mm")})</MenuItem>
                        <MenuItem value="America/Edmonton">Edmonton Time ({DateTime.now().setZone("America/Edmonton").toFormat("HH:mm")})</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="standard"
                        label="EMS Client ID"
                        value={request.clientId}
                        onChange={(e) => {
                            setRequest({
                                ...request,
                                clientId: parseInt(e.target.value, 10),
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel sx={{ mb: 0.5 }}>Event Info</InputLabel>
                    <ReactQuill
                        theme="snow"
                        value={request.eventInfo}
                        onChange={(html) => {
                            if (html !== request.eventInfo) {
                                setRequest({
                                    ...request,
                                    eventInfo: html,
                                });
                            }
                        }}
                        modules={toolbar}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel sx={{ mb: 0.5 }}>Operation Info</InputLabel>
                    <ReactQuill
                        theme="snow"
                        value={request.operationInfo}
                        onChange={(html) => {
                            if (html !== request.operationInfo) {
                                setRequest({
                                    ...request,
                                    operationInfo: html,
                                });
                            }
                        }}
                        modules={toolbar}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                    {onCancel ? (
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={() => {
                                onCancel();
                            }}
                        >
                            Cancel
                        </Button>
                    ) : (
                        <Box />
                    )}
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={!request.name || !request.eventStart || !request.eventEnd || !request.timezone || !request.clientId}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default EventForm;
