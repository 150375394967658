import {
    Button,
    Card,
    CardContent,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import * as socketio from "socket.io-client";
import Device, { DeviceInfo, PrinterStatus, ThermalStatus } from "../components/Device";
import { BACKEND_URI } from "../constants";
import AuthStore from "../stores/AuthStore";
import Toaster from "../common/Toaster";

enum GroupBy {
    Location,
    Urgency,
}

const PosView = () => {
    const [devices, setDevices] = React.useState<DeviceInfo[]>([]);
    const [groupBy, setGroupBy] = React.useState<GroupBy>(GroupBy.Location);
    const [filters, setFilters] = React.useState<number[]>([]);
    // const [filter, setFilter] = React.useState("");

    const [posRequest, setPosRequest] = React.useState<{
        identifier: number;
        location: string;
    } | null>(null);

    const socket = React.useRef<socketio.Socket | null>(null);

    React.useEffect(() => {
        socket.current = socketio.io(BACKEND_URI, { auth: { token: AuthStore.tokenString } });
        socket.current.on("list", (list: DeviceInfo[]) => setDevices(list));
        socket.current.on("filters", (f: number[]) => setFilters(f));
        return () => {
            socket.current?.disconnect();
        };
    }, []);

    const onUpdate = (device: any) => {
        socket.current?.emit("update", device);
        Toaster.info("POS updated successfully!");
    };

    const groupedDevices = devices
        .filter((it) => filters.length === 0 || filters.includes(it.identifier))
        .reduce((a, b) => {
            if (!b.location) {
                const NO_LOCATION = "Others";
                if (a.get(NO_LOCATION)) {
                    a.get(NO_LOCATION).push(b);
                } else {
                    a.set(NO_LOCATION, [b]);
                }
            } else {
                if (a.get(b.location)) {
                    a.get(b.location).push(b);
                } else {
                    a.set(b.location, [b]);
                }
            }
            return a;
        }, new Map<string, any>());

    return (
        <Container maxWidth="xl" sx={{ marginTop: "10px" }}>
            <Grid container>
                {Array.from(groupedDevices.keys())
                    .sort((a, b) => a.localeCompare(b))
                    .map((key) => (
                        <>
                            <Grid item sm={12}>
                                <Typography variant="h4" color="secondary" sx={{ margin: "10px" }}>
                                    {key}
                                </Typography>
                            </Grid>
                            {Array.from(groupedDevices, ([k, v]) =>
                                key === k ? (
                                    <>
                                        {v
                                            .sort((a, b) => a.identifier - b.identifier)
                                            .map((it) => (
                                                <Grid item md={6} sm={12} xs={12} key={`kiosk-${it.identifier}`}>
                                                    <Device {...it} onUpdate={onUpdate} onEdit={(pos) => setPosRequest(pos)} />
                                                </Grid>
                                            ))}
                                    </>
                                ) : (
                                    <></>
                                )
                            )}
                        </>
                    ))}
            </Grid>
            <Dialog open={!!posRequest} fullWidth>
                <DialogTitle>Modify POS {posRequest?.identifier}</DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" sx={{ padding: "10px" }}>
                        <TextField
                            label="Location"
                            value={posRequest?.location}
                            onChange={(e) => {
                                setPosRequest({ ...posRequest!, location: e.target.value });
                            }}
                        />
                        <Box display="flex" flexDirection="row" justifyContent="flex-end" sx={{ marginTop: "5px" }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setPosRequest(null);
                                }}
                                sx={{ marginRight: "5px" }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    onUpdate(posRequest);
                                    setPosRequest(null);
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default PosView;
