export interface Order {
    id: number;
    total: number;
    dateCreated: number;
    orderStatus: OrderStatus;
    salesContext: SalesContext;
    name: string;
    email: string;
    retrievalKey: string;
    cartUrl: string;
}

export enum OrderStatus {
    PURCHASED = "PURCHASED",
    PAYMENT_PENDING = "PAYMENT_PENDING",
    PAYMENT_FAILED = "PAYMENT_FAILED",
    REFUNDED = "REFUNDED",
}

export const OrderStatusFriendlyName = (status: OrderStatus) => {
    switch (status) {
        case OrderStatus.PURCHASED:
            return "Payment Successful";
        case OrderStatus.PAYMENT_PENDING:
            return "Payment Pending";
        case OrderStatus.PAYMENT_FAILED:
            return "Payment Failed";
        case OrderStatus.REFUNDED:
            return "Refunded";
        default:
            return "Unknown";
    }
};

export enum SalesContext {
    ECOMMERCE = "ECOMMERCE",
    BOXOFFICE = "BOXOFFICE",
    POS = "POS",
}

export const SalesContextFriendlyName = (context: SalesContext) => {
    switch (context) {
        case SalesContext.ECOMMERCE:
            return "Online Order";
        case SalesContext.BOXOFFICE:
            return "Box Office Order";
        default:
            return "Unknown";
    }
};

export const SalesContextColor = (context: any) => {
    switch (context) {
        case "ECOMMERCE":
            return "success";
        case "BOXOFFICE":
            return "primary";
        case "POS":
            return "warning";
        default:
            return "secondary";
    }
};
