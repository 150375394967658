import React from "react";
import { BACKEND_URI } from "../../constants";
import Fetch from "../../api/Fetch";
import { Event } from "../../model/Types";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import EventForm from "../../components/event/EventForm";
import { DateTime } from "luxon";
import Toaster from "../../common/Toaster";
import { Link } from "react-router-dom";

const AdminEvents = () => {
    const [events, setEvents] = React.useState<Event[]>([]);
    const [showEventDialog, setShowEventDialog] = React.useState<boolean>(false);
    const [currentEvent, setCurrentEvent] = React.useState<Event | undefined>(undefined);

    const cb = React.useCallback(async () => {
        try {
            const body = await Fetch<Event[]>(`${BACKEND_URI}/admin/event`, {
                includeAuth: true,
            });
            setEvents(body);
        } catch (err) {
            console.log(err);
        }
    }, []);

    async function onSubmit(request: any) {
        try {
            await Fetch(`${BACKEND_URI}/admin/event`, {
                method: "POST",
                body: request,
                includeAuth: true,
            });
            handleClose();
            Toaster.success(`Event ${request.id ? "updated" : "created"} successfully.`);
            cb();
        } catch (err: any) {
            console.log(err);
            Toaster.error(err.message || "An error occurred while adding event.");
        }
    }

    function handleClose() {
        setShowEventDialog(false);
        setCurrentEvent(undefined);
    }

    React.useEffect(() => {
        cb();
    }, [cb]);

    return (
        <Box>
            <Card>
                <CardContent>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h5">Events</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setShowEventDialog(true);
                            }}
                        >
                            Add Event
                        </Button>
                    </Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Event</TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                                <TableCell>Time Zone</TableCell>
                                <TableCell>EMS Client ID</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {events
                                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                                .map((event) => (
                                    <TableRow key={event.id}>
                                        <TableCell>{event.name}</TableCell>
                                        <TableCell>{event.eventStart}</TableCell>
                                        <TableCell>{event.eventEnd}</TableCell>
                                        <TableCell>{event.timezone}</TableCell>
                                        <TableCell>{event.clientId}</TableCell>
                                        <TableCell>
                                            <Box>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        setCurrentEvent(event);
                                                        setShowEventDialog(true);
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                                <Link to={`/admin/event/${event.id}`} style={{ marginLeft: 10 }}>
                                                    <Button variant="contained" color="secondary">
                                                        View
                                                    </Button>
                                                </Link>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <Dialog open={showEventDialog}>
                <DialogTitle>Add Event</DialogTitle>
                <DialogContent>
                    <EventForm
                        event={currentEvent}
                        onSubmit={onSubmit}
                        onCancel={() => {
                            handleClose();
                        }}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default AdminEvents;
