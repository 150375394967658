import { Grid, Button, Card, CardContent, Typography, Box } from "@mui/material";
import { Buttons } from "./LoggedIn";
import DeviceView from "./DeviceView";
import { NavLink } from "react-router-dom";
import AuthStore from "../stores/AuthStore";
import { observer } from "mobx-react";

const LandingView = observer(() => (
    <Grid container sx={{ height: "100%" }}>
        <Grid item xs={12} md={AuthStore.hasAdmin ? 6 : 12} sx={{ display: "flex", marginBottom: "20px" }}>
            <Grid container spacing={2} justifyContent="center">
                {Buttons()
                    .filter((it) => !it.disabled)
                    .map((it) => (
                        <Grid item xs={12} md={AuthStore.hasAdmin ? 6 : 2} key={`landing-button-${it.title}`} sx={{ textAlign: "center" }}>
                            <NavLink to={it.disabled ? "/" : it.to}>
                                <Button disabled={it.disabled}>
                                    <Card
                                        sx={{
                                            height: "150px",
                                            width: "150px",
                                            boxShadow: 10,
                                        }}
                                    >
                                        <CardContent>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                {it.largeIcon}
                                                <Typography variant="body1" sx={{ textAlign: "center" }}>
                                                    {it.title.toUpperCase()}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Button>
                            </NavLink>
                        </Grid>
                    ))}
            </Grid>
        </Grid>
        {AuthStore.hasAdmin && (
            <Grid item md={6} sx={{ height: "100%" }}>
                <Card sx={{ height: "100%", overflowY: "scroll" }}>
                    <CardContent>
                        <DeviceView />
                    </CardContent>
                </Card>
            </Grid>
        )}
    </Grid>
));

export default LandingView;
