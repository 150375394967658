import AuthStore from "../stores/AuthStore";

type RequestProps = {
    body?: any;
    headers?: Record<string, string>;
    includeAuth?: boolean;
    isText?: boolean;
    method?: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
};

const Fetch = async <T>(uri: string, request?: RequestProps): Promise<T> => {
    const headers: Record<string, string> = {
        ...request?.headers,
        "Content-Type": "application/json",
    };
    if (request?.includeAuth) {
        headers["Authorization"] = `Bearer ${AuthStore.tokenString}`;
    }
    const res = await fetch(uri, {
        body: JSON.stringify(request?.body),
        headers: headers,
        method: request?.method ?? "GET",
    });
    if (!res.ok) {
        throw Error(await res.text());
    }
    return request?.isText ? await res.text() : await res.json();
};

export default Fetch;
