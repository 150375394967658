import React from "react";
import * as socketio from "socket.io-client";
import { BACKEND_URI } from "../constants";
import AuthStore from "../stores/AuthStore";
import Fetch from "../api/Fetch";
import { Box, Button, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";

const RoomCounter = () => {
    const socket = React.useRef<socketio.Socket | null>(null);
    const [room, setRoom] = React.useState<{
        name: string;
        count: number;
        capacity: number;
    } | null>(null);
    const [roomId, setRoomId] = React.useState<number | null>(null);
    const roomIdRef = React.useRef<number | null>(null);
    const [count, setCount] = React.useState<number>(0);

    const [rooms, setRooms] = React.useState<
        {
            id: number;
            name: string;
            count: number;
        }[]
    >([]);

    const getRooms = async () => {
        try {
            const res = await Fetch<any>(`${BACKEND_URI}/rooms`, {
                includeAuth: true,
            });
            setRooms(res);
        } catch (e) {
            console.error(e);
        }
    };

    function wireEvents() {
        socket.current?.on("connect", () => {
            setTimeout(() => {
                if (roomIdRef.current) {
                    socket.current?.emit("room-counter", { roomId: roomIdRef.current });
                }
            }, 1_000);
        });
        socket.current?.on("room-count", (r: any) => setRoom(r));
    }

    React.useEffect(() => {
        getRooms();
        socket.current = socketio.io(BACKEND_URI, { auth: { token: AuthStore.tokenString } });
        wireEvents();
        return () => {
            socket.current?.disconnect();
        };
    }, []);

    return (
        <Box sx={{ height: "90%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            {!roomId && (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "12px" }}>
                    <Typography sx={{ textAlign: "center" }} variant="h4">
                        Select a room
                    </Typography>
                    {rooms.map((it) => (
                        <Button
                            key={it.id}
                            onClick={(e) => {
                                setRoomId(it.id);
                                roomIdRef.current = it.id;
                                socket.current?.emit("room-counter", { roomId: it.id });
                            }}
                            variant="contained"
                        >
                            {it.name}
                        </Button>
                    ))}
                </Box>
            )}
            {room && (
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                    <Typography sx={{ textAlign: "center" }} variant="h4">
                        {room.name}
                    </Typography>
                    <Typography sx={{ textAlign: "center" }} variant="h3">
                        <span style={{ color: room.capacity !== 0 && room.count > room.capacity ? "red" : "inherit" }}>{room.count}</span>
                        {room.capacity !== 0 && ` / ${room.capacity}`}
                    </Typography>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                        <Button
                            onClick={() => {
                                socket.current?.emit("room-counter-increment", { roomId });
                            }}
                            sx={{
                                borderRadius: "50%",
                                height: "100px",
                                width: "100px",
                            }}
                            variant="contained"
                            color="success"
                        >
                            ENTER
                        </Button>
                        <Button
                            onClick={() => {
                                socket.current?.emit("room-counter-decrement", { roomId });
                            }}
                            sx={{
                                borderRadius: "50%",
                                height: "100px",
                                width: "100px",
                            }}
                            variant="contained"
                            color="error"
                        >
                            EXIT
                        </Button>
                    </Box>
                    {AuthStore.hasAdmin && (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
                            <Typography variant="h5" sx={{ textAlign: "center" }}>
                                Admin Options
                            </Typography>
                            <TextField
                                label="Set Count"
                                type="number"
                                value={count}
                                onChange={(e) => {
                                    setCount(Math.max(parseInt(e.target.value), 0));
                                }}
                            />
                            <Box sx={{ display: "flex", gap: "10px" }}>
                                <Button
                                    onClick={() => {
                                        socket.current?.emit("room-counter-reset", { roomId });
                                    }}
                                    variant="contained"
                                    color="warning"
                                >
                                    RESET
                                </Button>
                                <Button
                                    onClick={() => {
                                        socket.current?.emit("room-counter-set", { roomId, count });
                                    }}
                                    variant="contained"
                                    color="success"
                                >
                                    SET
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default RoomCounter;
