import { Card, CardContent, Checkbox, Divider, FormControlLabel, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Fetch from "../api/Fetch";
import { BACKEND_URI } from "../constants";
import AuthStore from "../stores/AuthStore";
import { DailyTask } from "../model/Types";

const DailyTasksView = () => {
    const [tasks, setTasks] = React.useState<{ id: number; task: string; weight?: number; complete?: boolean }[]>([]);

    const cb = React.useCallback(async () => {
        try {
            const data = await Fetch<DailyTask[]>(`${BACKEND_URI}/event-daily-task`, {
                includeAuth: true,
            });
            setTasks(data);
        } catch (err) {
            console.log(err);
        }
    }, []);

    React.useEffect(() => {
        cb();
    }, [cb]);

    return (
        <Box>
            <Card>
                <CardContent>
                    <Typography variant="h6">Daily Checklist</Typography>
                    <Divider />
                    <Box sx={{ marginLeft: "10px" }}>
                        {tasks
                            .sort((a, b) => (a.weight ?? 99) - (b.weight ?? 99))
                            .map((it) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e, checked) => {
                                                setTasks([
                                                    ...tasks.filter((it2) => it2.id !== it.id),
                                                    {
                                                        ...it,
                                                        complete: checked,
                                                    },
                                                ]);
                                            }}
                                        />
                                    }
                                    label={<span style={{ textDecoration: it.complete ? "line-through" : "none" }}>{it.task}</span>}
                                    sx={{ display: "block" }}
                                />
                            ))}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default DailyTasksView;
