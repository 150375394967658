import { Box, TextField, Button, Card, CardContent, Divider, Dialog, Typography, DialogTitle, DialogContent } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import BarcodeIcon from "@mui/icons-material/QrCode";
import BarcodeScanner from "../components/BarcodeScanner";
import AuthStore from "../stores/AuthStore";
import ZxingScanner from "../components/ZxingScanner";

const Login = observer(() => {
    const [request, setRequest] = React.useState({ pin: "" });
    // const [cameraOpen, setCameraOpen] = React.useState(false);

    const onSubmit = (e: any) => {
        e.preventDefault();
        try {
            AuthStore.pinLogin(request);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
            <Card sx={{ width: "400px", height: "500px", maxWidth: "90%" }}>
                <CardContent sx={{ height: "100%" }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <img src="/img/ag-logo-white.png" style={{ width: "225px", marginBottom: "15px" }} />
                        {AuthStore.flashMessage && (
                            <Typography variant="body1" color="pink" sx={{ marginBottom: "5px" }}>
                                Error: {AuthStore.flashMessage}
                            </Typography>
                        )}
                        {/* <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            component={"form"}
                            onSubmit={onSubmit}
                        >
                            <TextField
                                label="Enter Badge UUID"
                                size="small"
                                sx={{ marginBottom: "10px" }}
                                value={request.barcode}
                                onChange={(e) => setRequest({ ...request, barcode: e.target.value })}
                                autoFocus
                                disabled={AuthStore.loading}
                            />
                            <Button variant="contained" type="submit" sx={{ marginBottom: "20px" }} disabled={AuthStore.loading || !request.barcode}>
                                Login
                            </Button>
                        </Box>
                        <Box sx={{ width: "100%", marginBottom: "20px" }}>
                            <Divider>OR</Divider>
                        </Box> */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            component={"form"}
                            onSubmit={onSubmit}
                        >
                            <TextField
                                label="Enter PIN"
                                size="small"
                                value={request.pin}
                                sx={{ marginBottom: "10px" }}
                                onChange={(e) => setRequest({ ...request, pin: e.target.value })}
                                autoFocus
                                disabled={AuthStore.loading}
                                fullWidth
                                type="password"
                            />
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ marginBottom: "20px" }}
                                disabled={AuthStore.loading || !request.pin}
                                fullWidth
                            >
                                Login
                            </Button>
                        </Box>
                        {/* <Button variant="contained" onClick={() => setCameraOpen(true)} disabled={AuthStore.loading}>
                            <BarcodeIcon sx={{ marginRight: "5px" }} /> Scan Badge
                        </Button> */}
                    </Box>
                </CardContent>
            </Card>

            {/* <Dialog open={cameraOpen} onClose={() => setCameraOpen(false)}>
                <DialogTitle>Scan Badge</DialogTitle>
                <DialogContent dividers sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <BarcodeScanner
                        onScan={(barcode: string) => {
                            setCameraOpen(false);
                            AuthStore.barcodeLogin(barcode);
                        }}
                    />
                </DialogContent>
            </Dialog> */}
        </Box>
    );
});

export default Login;
