import { makeAutoObservable } from "mobx";
import jwtDecode from "jwt-decode";
import AuthEndpoints from "../api/AuthEndpoints";

const LOCAL_STORAGE_KEY = "ems.punchclock.latestToken";

export enum AccessLevel {
    IDENTITY_MANAGER = "IDENTITY_MANAGER",
    SCHEDULE_MANAGER = "SCHEDULE_MANAGER",
    SCHEDULE_ADMIN = "SCHEDULE_ADMIN",
    SCHEDULE_AND_PUNCH_CLOCK_ADMIN = "SCHEDULE_AND_PUNCH_CLOCK_ADMIN",
}

interface JWTAccessLevelProvisioning {
    clientId: number;
    accessLevel: AccessLevel;
}

interface Session {
    id: number;
    firstName: string | null;
    lastName: string | null;
    username: string | null;
    uuid: string | null;
    admin: boolean;
    accessLevels: Array<JWTAccessLevelProvisioning>;
    externalAccess: Array<{
        targetSystem: string;
        clientId: number;
        operation: string;
        parameters: { [key: string]: string | null };
    }>;
}

interface SessionState {
    loggedIn: boolean;
    latestToken: string;
    flashMessage: string;
    session: Session;
}

const DefaultState = (): SessionState => {
    return {
        loggedIn: false,
        latestToken: "",
        flashMessage: "",
        session: {
            id: -1,
            firstName: null,
            lastName: null,
            username: null,
            uuid: null,
            admin: false,
            accessLevels: [],
            externalAccess: [],
        },
    };
};

const Now = () => new Date().getTime() / 1000;

class AuthStore {
    private _state: SessionState = DefaultState();
    public loading: boolean = true;

    private timerRef: NodeJS.Timer | null = null;

    constructor() {
        makeAutoObservable(this);

        this.readLocalStorage();
        this.setLoading(false);
        this.timerRef = setInterval(() => {
            this.checkJwt();
        }, 10_000);
    }

    setLoading(l: boolean) {
        this.loading = l;
    }

    login(request: { username: string; password: string; reCaptchaResponse: string }): Promise<any> {
        this.setLoading(true);
        return new Promise((resolve, reject) => {
            AuthEndpoints.getToken(request)
                .then((session) => {
                    this.parseJwt(session.token);
                    resolve(session);
                })
                .catch((err) => {
                    console.error(`Error logging in: ${err}`);
                    this._state.flashMessage = err;
                    reject(err);
                })
                .finally(() => {
                    this.setLoading(false);
                });
        });
    }

    barcodeLogin(barcode: string): Promise<any> {
        this.setLoading(true);
        return new Promise((resolve, reject) => {
            AuthEndpoints.getBarcodeToken({ barcode })
                .then((session) => {
                    this.parseJwt(session.token);
                    resolve(session);
                })
                .catch((err) => {
                    console.error(`Error logging in: ${err}`);
                    this._state.flashMessage = err;
                    reject(err);
                })
                .finally(() => {
                    this.setLoading(false);
                });
        });
    }

    pinLogin(request: { pin: string }): Promise<any> {
        this.setLoading(true);
        return new Promise((resolve, reject) => {
            AuthEndpoints.getPinToken(request)
                .then((session) => {
                    this.parseJwt(session.token);
                    resolve(session);
                })
                .catch((err) => {
                    console.error(`Error logging in: ${err}`);
                    this._state.flashMessage = err;
                    reject(err);
                })
                .finally(() => {
                    this.setLoading(false);
                });
        });
    }

    updateToken(t: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.parseJwt(t);
            resolve(true);
        });
    }

    logout() {
        this._state = DefaultState();
        this.clearLocalStorage();
    }

    get session(): Session {
        return this._state.session;
    }

    get accessLevels(): Array<JWTAccessLevelProvisioning> {
        return this.session ? this.session.accessLevels : [];
    }

    get username(): string {
        if (this.loggedIn) {
            return this.session.username ?? "";
        } else {
            return "";
        }
    }

    get loggedIn(): boolean {
        return this._state.loggedIn;
    }

    get tokenString(): string {
        return this._state.latestToken;
    }

    get hasAdmin(): boolean {
        return this._state.loggedIn && this._state.session.admin;
    }

    get flashMessage(): string {
        return this._state.flashMessage;
    }

    get fullName(): string {
        return this.session.firstName + " " + this.session.lastName;
    }

    get userId(): number {
        return this.session.id;
    }

    hasAccess(operation: string): boolean {
        return (
            this._state.session?.admin ||
            this._state.session?.externalAccess.some(
                (it) =>
                    (it.targetSystem === "onsite" && it.operation === operation) ||
                    (it.targetSystem === "onsite" && it.operation === "ALL") ||
                    it.targetSystem === "ALL"
            )
        );
    }

    private parseJwt(t: string) {
        this._state.latestToken = t;
        const token = jwtDecode<any>(t);
        if (token && token.exp - Now() > 0) {
            if (!(token.admin || token.externalAccess.findIndex((ea: any) => ea.targetSystem === "onsite" || ea.targetSystem === "ALL") >= 0)) {
                throw "Not authorized to use the manager portal.";
            }
            this._state.session = {
                id: token.id,
                firstName: token.firstName,
                lastName: token.lastName,
                username: token.username,
                uuid: `ems2:${token.sub}`,
                admin: token.admin,
                accessLevels: token.accessLevels,
                externalAccess: token.externalAccess,
            };
            this._state.flashMessage = "";
            this._state.loggedIn = true;
            this.saveLocalStorage();
        } else {
            this._state = DefaultState();
        }
    }

    private checkJwt() {
        if (this.tokenString) {
            const token = jwtDecode<any>(this.tokenString);
            if (token && token.exp - Now() < 0) {
                this._state = DefaultState();
            }
        }
    }

    private saveLocalStorage() {
        localStorage.setItem(LOCAL_STORAGE_KEY, this.tokenString);
    }

    private clearLocalStorage() {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    }

    private readLocalStorage() {
        const token = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (token) {
            this.parseJwt(token);
        }
    }
}

export default new AuthStore();
