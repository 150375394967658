import { EMS_BACKEND_URI } from "../constants";

export interface TokenResponse {
    token: string;
}

class AuthEndpoints {
    getToken(request: { username: string; password: string; reCaptchaResponse: string }): Promise<TokenResponse> {
        return new Promise((resolve, reject) => {
            fetch(`${EMS_BACKEND_URI}/authentication`, {
                cache: "no-cache",
                method: "POST",
                body: JSON.stringify(request),
                headers: {
                    "content-type": "application/json",
                },
                mode: "cors",
                referrer: "no-referrer",
            })
                .then((response: Response) => {
                    const contentType = response.headers.get("content-type");
                    if (contentType === "application/json") {
                        if (response.ok) {
                            response.json().then((r) => resolve(r));
                        } else {
                            response.json().then((r) => reject(r));
                        }
                    } else {
                        response.text().then((r) => reject(r));
                    }
                })
                .catch((err) => reject(err));
        });
    }

    getBarcodeToken(request: { barcode: string }): Promise<TokenResponse> {
        return new Promise((resolve, reject) => {
            fetch(`${EMS_BACKEND_URI}/authentication/barcode`, {
                cache: "no-cache",
                method: "POST",
                body: JSON.stringify(request),
                headers: {
                    "content-type": "application/json",
                },
                mode: "cors",
                referrer: "no-referrer",
            })
                .then((response: Response) => {
                    const contentType = response.headers.get("content-type");
                    if (contentType === "application/json") {
                        if (response.ok) {
                            response.json().then((r) => resolve(r));
                        } else {
                            response.json().then((r) => reject(r));
                        }
                    } else {
                        response.text().then((r) => reject(r));
                    }
                })
                .catch((err) => reject(err));
        });
    }

    getPinToken(request: { pin: string }): Promise<TokenResponse> {
        return new Promise((resolve, reject) => {
            fetch(`${EMS_BACKEND_URI}/authentication/manager-pin`, {
                cache: "no-cache",
                method: "POST",
                body: JSON.stringify(request),
                headers: {
                    "content-type": "application/json",
                },
                mode: "cors",
                referrer: "no-referrer",
            })
                .then((response: Response) => {
                    const contentType = response.headers.get("content-type");
                    if (contentType === "application/json") {
                        if (response.ok) {
                            response.json().then((r) => resolve(r));
                        } else {
                            response.json().then((r) => reject(r));
                        }
                    } else {
                        response.text().then((r) => reject(r));
                    }
                })
                .catch((err) => reject(err));
        });
    }
}

export default new AuthEndpoints();
