import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        // type: "dark",
        mode: "dark",
        primary: {
            main: "#386396",
        },
        secondary: {
            main: "#ff8733",
        },
        background: {
            default: "#05212c",
            paper: "#002b3c",
        },
    },
});

export default theme;
