import { Button, Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { BACKEND_URI } from "../../constants";
import { DailyInfo } from "../../model/Types";
import ReactQuill from "react-quill";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import AuthStore from "../../stores/AuthStore";
import Fetch from "../../api/Fetch";
import { observer } from "mobx-react";
import EventStore from "../../stores/EventStore";
import Toaster from "../../common/Toaster";

export const toolbar = {
    toolbar: [
        [{ font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["link", "image"],
        ["clean"],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

const AdminDailyInfo = observer(() => {
    const [dailyInfo, setDailyInfo] = React.useState<DailyInfo[]>([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [currentInfo, setCurrentInfo] = React.useState<any>(undefined);

    const cb = React.useCallback(async () => {
        try {
            const body = await Fetch<DailyInfo[]>(`${BACKEND_URI}/admin/event/${EventStore.eventId}/daily-info`, {
                includeAuth: true,
            });
            setDailyInfo(body);
        } catch (err) {
            console.log(err);
        }
    }, []);

    React.useEffect(() => {
        cb();
    }, [cb]);

    const onChangeHtml = (html: string) => {
        setCurrentInfo({
            ...currentInfo,
            html,
        });
    };

    const dateOnChange = (date: DateTime | null) => {
        setCurrentInfo({
            ...currentInfo,
            date: date?.toFormat("yyyy-MM-dd"),
        });
    };

    const onSave = async (e: any) => {
        const existing = dailyInfo.some((it) => it.date === currentInfo.date);
        if (existing && !currentInfo?.id) {
            window.alert("Info for this day already exists. Please update it from the list.");
            setOpen(false);
            return;
        }
        try {
            const body = await Fetch(`${BACKEND_URI}/admin/event/${EventStore.eventId}/daily-info`, {
                includeAuth: true,
                body: currentInfo,
                method: "POST",
            });
            Toaster.success("Daily info saved successfully.");
            cb();
            setOpen(false);
        } catch (err: any) {
            console.error(err);
            Toaster.error(err.message || "An error occurred while saving daily info.");
        }
    };

    return (
        <Box>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        setCurrentInfo({
                            date: DateTime.now().toFormat("yyyy-MM-dd"),
                            html: "",
                        });

                        setOpen(true);
                    }}
                >
                    Create
                </Button>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell width="195px" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dailyInfo
                        .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
                        .map((it) => (
                            <TableRow>
                                <TableCell>{it.date}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        sx={{ marginRight: "8px" }}
                                        onClick={() => {
                                            setCurrentInfo(it);
                                            setOpen(true);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button variant="contained" color="secondary" onClick={() => alert("TODO: Add delete for daily info.")}>
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <Dialog open={open} maxWidth="lg" fullWidth onClose={() => setOpen(false)}>
                <DialogTitle>
                    {currentInfo?.id ? "Edit" : "Create"} Daily Info for {currentInfo?.date}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ margin: "10px" }}>
                        {!currentInfo?.id && (
                            <DatePicker
                                label="Date"
                                onChange={dateOnChange}
                                renderInput={(params) => <TextField {...params} />}
                                value={DateTime.fromFormat(currentInfo?.date ?? "2000-01-01", "yyyy-MM-dd")}
                                shouldDisableDate={(date) => {
                                    if (!EventStore.event) return true;
                                    return (
                                        date < DateTime.fromFormat(EventStore.event.eventStart, "yyyy-MM-dd") ||
                                        date > DateTime.fromFormat(EventStore.event.eventEnd, "yyyy-MM-dd")
                                    );
                                }}
                            />
                        )}
                        <Box sx={{ margin: "10px" }} />
                        <ReactQuill theme="snow" value={currentInfo?.html ?? ""} onChange={onChangeHtml} modules={toolbar} />
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "8px" }}>
                            <Button variant="contained" color="secondary" sx={{ marginRight: "8px" }} onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={onSave}>
                                {currentInfo?.id ? "Save" : "Create"}
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
});

export default AdminDailyInfo;
