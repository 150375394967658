import {
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import TimeAgo from "react-time-ago";
import BatteryIcon from "@mui/icons-material/BatteryFull";
import ThermometerIcon from "@mui/icons-material/Thermostat";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { observer } from "mobx-react";
import AuthStore from "../stores/AuthStore";
import Fetch from "../api/Fetch";
import { BACKEND_URI } from "../constants";
import { SalesContextColor } from "../model/Order";
import CloudIcon from "@mui/icons-material/Cloud";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

export enum PrinterStatus {
    GOOD = "GOOD",
    PAPER_EMPTY = "PAPER_EMPTY",
    COVER_OPEN = "COVER_OPEN",
    PRINTER_OFFLINE = "PRINTER_OFFLINE",
    PAPER_ERROR = "PAPER_ERROR",
    RIBBON_END = "RIBBON_END",
    BATCH_PROCESSING = "BATCH_PROCESSING",
    PRINTING = "PRINTING",
    PAUSED = "PAUSED",
    WAITING_FOR_PEELING = "WAITING_FOR_PEELING",
}

const printerStatusFriendlyName = (status: PrinterStatus): string => {
    switch (status) {
        case PrinterStatus.GOOD:
            return "Printer is running normally.";
        case PrinterStatus.PAPER_EMPTY:
            return "Printer is out of paper.";
        case PrinterStatus.COVER_OPEN:
            return "Printer cover is open.";
        case PrinterStatus.PRINTER_OFFLINE:
            return "Printer is offline.";
        case PrinterStatus.PAPER_ERROR:
            return "Printer has a paper error.";
        case PrinterStatus.RIBBON_END:
            return "Printer is out of ribbon.";
        case PrinterStatus.BATCH_PROCESSING:
            return "Printer is in the middle of a batch.";
        case PrinterStatus.PRINTING:
            return "Printer is active.";
        case PrinterStatus.PAUSED:
            return "Printer is paused.";
        case PrinterStatus.WAITING_FOR_PEELING:
            return "Printer is waiting for peeling.";
        default:
            return "Unknown printer state.";
    }
};

const printerColorFromStatus = (status: PrinterStatus): string => {
    switch (status) {
        case PrinterStatus.GOOD:
        case PrinterStatus.BATCH_PROCESSING:
        case PrinterStatus.PRINTING:
            return "#10d15a";
        case PrinterStatus.PAUSED:
        case PrinterStatus.WAITING_FOR_PEELING:
            return "#d18610";
        default:
            return "#db3232";
    }
};

export enum ThermalStatus {
    GOOD = "GOOD",
    WARM = "WARM",
    HOT = "HOT",
    CRITICAL = "CRITICAL",
    NOT_SUPPORTED = "NOT_SUPPORTED",
}

const thermalStatusFriendlyName = (status: ThermalStatus): string => {
    switch (status) {
        case ThermalStatus.GOOD:
            return "GOOD";
        case ThermalStatus.WARM:
            return "WARM";
        case ThermalStatus.HOT:
            return "HOT";
        case ThermalStatus.CRITICAL:
            return "CRITICAL";
        default:
            return "NOT SUPPORTED";
    }
};

// const thermalColorFromStatus = (status: ThermalStatus): string => {
//     switch (status) {
//         case ThermalStatus.GOOD:
//             return "#7affba";
//         case ThermalStatus.WARM:
//             return "#ffbf7a";
//         case ThermalStatus.HOT:
//             return "#ff8e7a";
//         case ThermalStatus.CRITICAL:
//             return "#ff7a7a";
//         default:
//             return "#ff7a7a";
//     }
// };

export type DeviceInfo = {
    identifier: number;
    battery: string;
    printerStatus: PrinterStatus;
    thermalStatus: ThermalStatus;
    user: string;
    updatedAt: string;
    checkedInAt: string;
    location: string;
    configuration:
        | ({
              compsEnabled: boolean;
              doubleWideTokens: boolean;
              kioskConfig: any | null;
              kioskMode: boolean;
              masterPassword: boolean;
              nodeNumber: number | null;
              offline: boolean;
              pingEnabled: boolean;
              printerEnabled: boolean;
              salesContext: "POS" | "BOXOFFICE" | "ECOMMERCE";
              serialNumber: number;
              squareEnabled: boolean;
              takeAllMode: boolean;
              timezone: string | null;
          } & { [lbl: string]: any })
        | null;
};

const Device = observer(
    ({
        identifier,
        battery,
        printerStatus,
        thermalStatus,
        user,
        updatedAt,
        checkedInAt,
        location,
        onUpdate,
        showLocation,
        configuration,
        onEdit,
        onSetConfig,
    }: DeviceInfo & {
        onUpdate: (request: any) => void;
        showLocation?: boolean;
        onEdit: ({ identifier, location }: { identifier: number; location: string }) => void;
        onSetConfig: ({ identifier, config }) => void;
    }) => {
        const [open, setOpen] = React.useState(false);
        const [sLocation, setSLocation] = React.useState(location);
        const [menuAnchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const menuOpen = Boolean(menuAnchorEl);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        const onLocationChange = (e: any) => {
            e.preventDefault();
            setSLocation(e.target.value);
        };

        const onCancel = () => {
            setOpen(false);
        };

        const onRefreshKiosk = () => {
            Fetch(`${BACKEND_URI}/pos/refresh-kiosk`, {
                includeAuth: true,
                method: "POST",
                body: { nodeNumbers: [identifier] },
            }).catch((err) => {});
        };

        const onRefreshEventList = () => {
            Fetch(`${BACKEND_URI}/pos/refresh-event-list`, {
                includeAuth: true,
                method: "POST",
                body: { nodeNumbers: [identifier] },
            }).catch((err) => {});
        };

        const onToggleOutOfServiceMessage = (showMessage: boolean) => {
            Fetch(`${BACKEND_URI}/pos/toggle-out-of-service-message`, {
                includeAuth: true,
                method: "POST",
                body: { showMessage, nodeNumbers: [identifier] },
            }).catch((err) => {});
        };

        const onSetTakeAllMode = (enabled: boolean) => {
            Fetch(`${BACKEND_URI}/pos/set-take-all-mode`, {
                includeAuth: true,
                method: "POST",
                body: { enabled, nodeNumbers: [identifier] },
            }).catch((err) => {});
        };

        const onSave = () => {
            onUpdate({
                identifier,
                location: sLocation,
            });
            onCancel();
        };

        return (
            <Card sx={{ backgroundColor: printerColorFromStatus(printerStatus), margin: "10px" }}>
                <CardContent>
                    <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight="125px">
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                            <Typography variant="h5" fontWeight="bold" color="black" sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                POS {identifier}{" "}
                                {AuthStore.hasAdmin && configuration && (
                                    <>
                                        <IconButton size="small" onClick={() => onSetConfig({ identifier, config: configuration })}>
                                            <QuestionMarkIcon fontSize="small" color="primary" />
                                        </IconButton>
                                        <Tooltip title={(configuration.offline ? "Offline" : "Online") + " mode"}>
                                            {configuration.offline ? <CloudOffIcon fontSize="small" /> : <CloudIcon fontSize="small" />}
                                        </Tooltip>
                                        {configuration.takeAllMode && <Chip label="Take All" size="small" color="primary" />}
                                    </>
                                )}
                            </Typography>
                            <Box display="flex" flexDirection="column">
                                <Box display="flex" alignItems="center" justifyContent="flex-end">
                                    <BatteryIcon fontSize="small" sx={{ color: "black" }} />
                                    <Typography variant="h6" color="black">
                                        {parseInt(battery, 10)}%
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <ThermometerIcon fontSize="small" sx={{ color: "black" }} />
                                    <Typography variant="h6" color="black">
                                        {thermalStatusFriendlyName(thermalStatus)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
                            <Box>
                                <Typography variant="body1" color="black">
                                    Current User: {user}
                                </Typography>
                                {location && showLocation && (
                                    <Typography variant="body2" fontWeight="bolder" color="black">
                                        {location}
                                    </Typography>
                                )}
                                <Typography variant="body2" color="black">
                                    Last Checked In: <TimeAgo date={Date.parse(checkedInAt)} locale="en-US" />
                                </Typography>
                            </Box>
                            <Box>
                                {AuthStore.hasAdmin && (
                                    <React.Fragment>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                onEdit({ identifier, location });
                                            }}
                                            sx={{ color: "black" }}
                                        >
                                            Edit
                                        </Button>
                                        <IconButton
                                            id="basic-button"
                                            aria-controls={menuOpen ? "basic-menu" : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={menuOpen ? "true" : undefined}
                                            onClick={handleClick}
                                        >
                                            <MenuIcon color="primary" />
                                        </IconButton>
                                    </React.Fragment>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
                {AuthStore.hasAdmin && (
                    <Menu
                        id="basic-menu"
                        anchorEl={menuAnchorEl}
                        open={menuOpen}
                        onClose={handleClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                onRefreshKiosk();
                                handleClose();
                            }}
                        >
                            Refresh Kiosk
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                onRefreshEventList();
                                handleClose();
                            }}
                        >
                            Refresh Event List
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                onToggleOutOfServiceMessage(true);
                                handleClose();
                            }}
                        >
                            Turn on Out of Service Message
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                onToggleOutOfServiceMessage(false);
                                handleClose();
                            }}
                        >
                            Turn off Out of Service Message
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                onSetTakeAllMode(true);
                                handleClose();
                            }}
                        >
                            Turn on Take All Mode
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                onSetTakeAllMode(false);
                                handleClose();
                            }}
                        >
                            Turn off Take All Mode
                        </MenuItem>
                    </Menu>
                )}
            </Card>
        );
    }
);

export default Device;
