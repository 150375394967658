import React from "react";
import { Event } from "../../model/Types";
import { observer } from "mobx-react";
import EventStore from "../../stores/EventStore";
import { Box } from "@mui/material";
import EventForm from "../../components/event/EventForm";

const AdminEvent = observer(() => {
    return (
        <Box>
            <EventForm
                event={EventStore.event ?? undefined}
                onSubmit={(event: Event) => {
                    EventStore.updateEvent(event);
                }}
            />
        </Box>
    );
});

export default AdminEvent;
