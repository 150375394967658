import {
    Button,
    Card,
    CardContent,
    Chip,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import React from "react";
import * as socketio from "socket.io-client";
import Fetch from "../api/Fetch";
import Device, { DeviceInfo, PrinterStatus, ThermalStatus } from "../components/Device";
import { BACKEND_URI } from "../constants";
import AuthStore from "../stores/AuthStore";
import RefreshIcon from "@mui/icons-material/Refresh";
import LockIcon from "@mui/icons-material/Lock";
import UnlockIcon from "@mui/icons-material/LockOpen";
import CloseIcon from "@mui/icons-material/Close";
import Toaster from "../common/Toaster";
import { SalesContextColor } from "../model/Order";

enum GroupBy {
    Location,
    Urgency,
}

const DeviceView = observer(() => {
    const [devices, setDevices] = React.useState<DeviceInfo[]>([]);
    const [groupBy, setGroupBy] = React.useState<GroupBy>(GroupBy.Urgency);
    const [filters, setFilters] = React.useState<number[]>([]);
    // const [filter, setFilter] = React.useState("");

    const [posRequest, setPosRequest] = React.useState<{
        identifier: number;
        location: string;
    } | null>(null);

    const [posConfig, setPosConfig] = React.useState<{
        identifier: number;
        config: DeviceInfo["configuration"];
    } | null>(null);
    const socket = React.useRef<socketio.Socket | null>(null);

    React.useEffect(() => {
        socket.current = socketio.io(BACKEND_URI, { auth: { token: AuthStore.tokenString } });
        socket.current.on("list", (list: DeviceInfo[]) => setDevices(list));
        socket.current.on("filters", (f: number[]) => setFilters(f));
        return () => {
            socket.current?.disconnect();
        };
    }, []);

    const onUpdate = (device: any) => {
        console.log("Connected");
        socket.current?.emit("update", device);
    };

    const onRefreshKiosk = () => {
        Fetch(`${BACKEND_URI}/pos/refresh-kiosk`, {
            includeAuth: true,
            method: "POST",
            body: {},
        }).catch((err) => {});
    };

    const onRefreshEventList = () => {
        Fetch(`${BACKEND_URI}/pos/refresh-event-list`, {
            includeAuth: true,
            method: "POST",
            body: {},
        }).catch((err) => {});
    };

    const onToggleOutOfServiceMessage = (showMessage: boolean) => {
        Fetch(`${BACKEND_URI}/pos/toggle-out-of-service-message`, {
            includeAuth: true,
            method: "POST",
            body: { showMessage },
        }).catch((err) => {});
    };

    const groupedDevices = devices
        .filter((it) => filters.length === 0 || filters.includes(it.identifier))
        .reduce((a, b) => {
            if (!b.location) {
                const NO_LOCATION = "Others";
                if (a.get(NO_LOCATION)) {
                    a.get(NO_LOCATION).push(b);
                } else {
                    a.set(NO_LOCATION, [b]);
                }
            } else {
                if (a.get(b.location)) {
                    a.get(b.location).push(b);
                } else {
                    a.set(b.location, [b]);
                }
            }
            return a;
        }, new Map<string, any>());

    const config = posConfig?.config;

    return (
        <Container maxWidth="xl" sx={{ marginTop: "10px" }}>
            <Grid container>
                <Grid item sm={12}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Box>
                            {AuthStore.hasAdmin && (
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                    <Button onClick={() => onRefreshKiosk()}>
                                        <RefreshIcon /> Refresh Kiosks
                                    </Button>
                                    <Button onClick={() => onRefreshEventList()}>
                                        <RefreshIcon /> Refresh Event Lists
                                    </Button>
                                    <Button onClick={() => onToggleOutOfServiceMessage(true)}>
                                        <LockIcon /> Turn On ALL Out of Service Messages
                                    </Button>
                                    <Button onClick={() => onToggleOutOfServiceMessage(false)}>
                                        <UnlockIcon /> Turn Off ALL Out of Service Messages
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        <Button
                            onClick={() => {
                                setGroupBy(groupBy === GroupBy.Location ? GroupBy.Urgency : GroupBy.Location);
                            }}
                            sx={{ marginRight: "5px" }}
                            color="primary"
                            variant="contained"
                        >
                            Group By {groupBy === GroupBy.Location ? "Urgency" : "Location"}
                        </Button>
                    </Box>
                </Grid>
                {groupBy === GroupBy.Urgency ? (
                    <>
                        {devices.filter(
                            (it) =>
                                (it.printerStatus !== PrinterStatus.GOOD || it.thermalStatus !== ThermalStatus.GOOD) &&
                                (filters.length === 0 || filters.includes(it.identifier))
                        ).length > 0 && (
                            <Grid item sm={12}>
                                <Typography variant="h4" color="secondary" sx={{ margin: "10px" }}>
                                    Requires Attention
                                </Typography>
                            </Grid>
                        )}
                        {devices
                            .filter(
                                (it) =>
                                    (it.printerStatus !== PrinterStatus.GOOD || it.thermalStatus !== ThermalStatus.GOOD) &&
                                    (filters.length === 0 || filters.includes(it.identifier))
                            )
                            .sort((a, b) => a.identifier - b.identifier)
                            .map((it, idx) => (
                                <Grid item md={12} sm={12} xs={12} key={`kiosk-${it.identifier}`}>
                                    <Device
                                        {...it}
                                        onUpdate={onUpdate}
                                        showLocation={true}
                                        onEdit={(pos) => setPosRequest(pos)}
                                        onSetConfig={(pos) => setPosConfig(pos)}
                                    />
                                </Grid>
                            ))}
                        {devices.filter(
                            (it) =>
                                it.printerStatus === PrinterStatus.GOOD &&
                                it.thermalStatus === ThermalStatus.GOOD &&
                                (filters.length === 0 || filters.includes(it.identifier))
                        ).length > 0 && (
                            <Grid item sm={12}>
                                <Typography variant="h4" color="primary" sx={{ margin: "10px" }}>
                                    Okay
                                </Typography>
                            </Grid>
                        )}
                        {devices
                            .filter(
                                (it) =>
                                    it.printerStatus === PrinterStatus.GOOD &&
                                    it.thermalStatus === ThermalStatus.GOOD &&
                                    (filters.length === 0 || filters.includes(it.identifier))
                            )
                            .sort((a, b) => a.identifier - b.identifier)
                            .map((it, idx) => (
                                <Grid item md={12} sm={12} xs={12} key={`kiosk-${it.identifier}-${idx}`}>
                                    <Device
                                        {...it}
                                        onUpdate={onUpdate}
                                        showLocation={true}
                                        onEdit={(pos) => setPosRequest(pos)}
                                        onSetConfig={(pos) => setPosConfig(pos)}
                                    />
                                </Grid>
                            ))}
                    </>
                ) : (
                    <>
                        {Array.from(groupedDevices.keys())
                            .sort((a, b) => a.localeCompare(b))
                            .map((key) => (
                                <>
                                    <Grid item sm={12}>
                                        <Typography variant="h4" color="secondary" sx={{ margin: "10px" }}>
                                            {key}
                                        </Typography>
                                    </Grid>
                                    {Array.from(groupedDevices, ([k, v]) =>
                                        key === k ? (
                                            <>
                                                {v
                                                    .sort((a, b) => a.identifier - b.identifier)
                                                    .map((it) => (
                                                        <Grid item md={12} sm={12} xs={12} key={`kiosk-${it.identifier}`}>
                                                            <Device
                                                                {...it}
                                                                onUpdate={onUpdate}
                                                                onEdit={(pos) => setPosRequest(pos)}
                                                                onSetConfig={(pos) => setPosConfig(pos)}
                                                            />
                                                        </Grid>
                                                    ))}
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    )}
                                </>
                            ))}
                    </>
                )}
            </Grid>
            {/* <Drawer anchor="right" open={showFilters} onClose={() => setShowFilters(false)}>
                <Box sx={{ padding: "10px" }}>
                    <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: "10px", width: "300px", maxWidth: "90%" }}>
                        Add POS' Filters
                    </Typography>
                    <Divider sx={{ marginBottom: "10px" }} />
                    <form onSubmit={onSubmit}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="POS #"
                            value={filter}
                            onChange={(e) => {
                                setFilter(e.target.value);
                            }}
                            type="number"
                        />
                    </form>
                    <Card sx={{ marginTop: "10px" }}>
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>POS #</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filters.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                                                No POS' filters
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {filters
                                        .sort((a: number, b: number) => a < b)
                                        .map((it: number) => (
                                            <TableRow>
                                                <TableCell>{it}</TableCell>
                                                <TableCell>
                                                    <Button variant="contained" color="error" onClick={() => onRemoveFilter(it)}>
                                                        Remove Filter
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Box>
            </Drawer> */}
            <Dialog open={!!posRequest} fullWidth>
                <DialogTitle>Modify POS {posRequest?.identifier}</DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" sx={{ padding: "10px" }}>
                        <TextField
                            label="Location"
                            value={posRequest?.location}
                            onChange={(e) => {
                                setPosRequest({ ...posRequest!, location: e.target.value });
                            }}
                        />
                        <Box display="flex" flexDirection="row" justifyContent="flex-end" sx={{ marginTop: "5px" }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    setPosRequest(null);
                                }}
                                sx={{ marginRight: "5px" }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    onUpdate(posRequest);
                                    Toaster.info("POS updated successfully!");
                                    setPosRequest(null);
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog open={!!posConfig} maxWidth="md" fullWidth>
                <DialogTitle>POS {posConfig?.identifier} Config</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setPosConfig(null)}
                    sx={(theme) => ({
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                {config && (
                    <React.Fragment>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h6">Sales Channel</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Chip label={config.salesContext} color={SalesContextColor(config.salesContext)} />
                                </Grid>
                            </Grid>
                            {config.salesContext === "BOXOFFICE" && (
                                <React.Fragment>
                                    <Divider sx={{ my: 1 }} />
                                    <Grid container>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="h6">COMPs Enabled</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Chip label={config.compsEnabled ? "Yes" : "No"} color={config.compsEnabled ? "success" : "error"} />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            )}
                            <Divider sx={{ my: 1 }} />
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h6">Kiosk Mode</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Chip label={config.kioskMode ? "Yes" : "No"} color={config.kioskMode ? "success" : "error"} />
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 1 }} />
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h6">Offline Mode</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Chip label={config.offline ? "Yes" : "No"} color={config.offline ? "success" : "error"} />
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 1 }} />
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h6">Printer Enabled</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Chip label={config.printerEnabled ? "Yes" : "No"} color={config.printerEnabled ? "success" : "error"} />
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 1 }} />
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h6">Square Enabled</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Chip label={config.squareEnabled ? "Yes" : "No"} color={config.squareEnabled ? "success" : "error"} />
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 1 }} />
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h6">Take-All Enabled</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Chip label={config.takeAllMode ? "Yes" : "No"} color={config.takeAllMode ? "success" : "error"} />
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 1 }} />
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h6">Master Password Set</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Chip label={config.masterPassword ? "Yes" : "No"} color={config.masterPassword ? "success" : "error"} />
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 1 }} />
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h6">Serial Number</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Chip label={config.serialNumber.toString()} color={"primary"} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </Container>
    );
});

export default DeviceView;
